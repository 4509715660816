import React from "react"
import * as styles from "./ServicesGrid.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const firstQuery = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/services/" } }) {
      edges {
        node {
          name
          id
          childImageSharp {
            gatsbyImageData(quality: 80, width: 300, placeholder: NONE)
          }
        }
      }
    }
  }
`

const contentArray = [
  {
    logo: "Website",
    header: "Custom Development",
    id: "customDevelopment",
    className: styles.Article,
    paragraph:
      "Get a team of experienced engineers and product owners to work for you on demand. Bring your ideas for web services, mobile applications or complex data based solutions. It doesn’t matter whether you are a startup looking to build the first MVP or a multinational corporation aiming to achieve new heights with a new digital product. In every situation our dedicated team of experts will offer technical skill mixed with understanding of business goal to help you in a journey from idea to a product. Depending on your needs, we can support you with all stages of the development process: ideation, technical analysis and design, development and maintanance.",
  },
  {
    logo: "Users",
    header: "Business Applications",
    id: "businessApplications",
    className: styles.ReversedArticle,
    footer: "We deliver and we do it fast.",
    paragraph: `We are a reliable technology partner. We will bring your ideas of mobile applications, web services and process automation to life. Blesu engineers created custom software for customers to cover employee onboarding, internal communication, core production management, document workflow and training. Thanks to that experience, we have gained a great understanding of many industries including, but not limited to automotive, retail, gastronomy and additive manufacturing. Solutions made by Blesu are being used by office, field and factory workers to improve the effectiveness of daily job.`,
  },
  {
    logo: "Pencil",
    header: "Product Design",
    id: "productDesign",
    className: styles.Article,
    paragraph:
      "User interface is how a digital product looks and user experience is a term describing how the same product feels in the hands of the user. We know that UI and UX are equally important and one can not exist without the other. In our work we highly focus on collaboration between designers and developers to deliver beautiful and reliable products. Even though we love to build digital products we know that sometimes development can wait for a little. That's why if you are in the product ideation phase, interviewing potential users, or preparing for an investor meeting we independently offer design concepts for your product. As such we lay foundations for the latter development phase.",
  },
  {
    logo: "Settings",
    header: "Digital Transformation",
    id: "digitalTransformation",
    className: styles.ReversedArticle,
    paragraph:
      "Digital transformation involves changing approach to boost organiosational effectiveness with the use of technology and digital tools. The main objective is to create new value for customers, which ibecomes increasingly challenging in the digital age. Nowadays customers demand personalised services coupled with almost real-time response. In order to provide such value, companies must adapt and optimise business processes. Digital transformation facilitates staying competetive through creating new digital communication channel that are beeing used internally and to provide value for the customer. It might be just one or couple processes that require improvement in order to make a major leap forward. In Blesu we create and provide tools to make this step.",
  },
]

export function ServicesGrid() {
  const data = useStaticQuery(firstQuery)
  const { edges: images = [] } = data.allFile

  return (
    <section className={styles.SectionWrapper}>
      {contentArray.map(
        ({ logo, header, paragraph, className = "", id = "", footer = "" }) => (
          <article id={id} key={logo} className={className}>
            <div className={styles.ImageWrapper}>
              <GatsbyImage
                image={getImage(
                  images.find(({ node }) => node.name === logo)?.node
                    ?.childImageSharp
                )}
                alt={"icon"}
                width={150}
                height={150}
                layout="constrained"
              />
            </div>
            <div className={styles.ContentWrapper}>
              <h3 className={styles.CustomHeader}>{header}</h3>
              <p className={styles.CustomParagraph}>{paragraph}</p>
              <p className={styles.CustomParagraph}>{footer}</p>
            </div>
          </article>
        )
      )}
    </section>
  )
}
