import React, { useContext, useEffect } from "react"

import Layout from "src/components/Layout"
import Seo from "../components/seo"
import ThemeContext, { Themes } from "src/theme/context"
import Contact from "src/components/Sections/Process/Contact"
import ServicesGrid from "src/components/Sections/Services/ServicesGrid"
import gsap, { Power1 } from "gsap"

const Services = ({ location = {} }) => {
  const { changeTheme } = useContext(ThemeContext)
  const { state = {} } = location
  const scrollTo = state?.scrollTo || ""
  const locationKey = state?.key || ""

  useEffect(() => {
    if (window && !!scrollTo && !!locationKey) {
      gsap.to(window, {
        duration: 2,
        ease: Power1.easeInOut,
        scrollTo: { y: `#${scrollTo}`, offsetY: 40 },
        offsetY: 40,
        delay: 0.4,
      })
    }
    return () => {}
  }, [scrollTo, locationKey])
  useEffect(() => {
    changeTheme(Themes.default)
  }, [])
  return (
    <Layout
      footerTheme={Themes.secondary}
      footerStyles={{ backgroundColor: "#6D7092" }}
    >
      <Seo title="What we do" description="We build digital products." />
      <ServicesGrid />
      <Contact />
    </Layout>
  )
}

export default Services
