import React from "react"
import clsx from "clsx"
import { Link } from "gatsby"

import * as styles from "./ContactForm.module.scss"
import { killAnimations } from "src/helpers/killTransitions"

export default function ContactForm() {
  return (
    <div className={clsx(styles.ContactForm, "animated-contact-form")}>
      <p>Let’s talk and start a project together</p>
      <Link to="/contact" onClick={killAnimations}>
        <button>Contact us</button>
      </Link>
    </div>
  )
}
