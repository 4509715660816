import React from "react"
import * as styles from "./Contact.module.scss"
import ContactForm from "src/components/Sections/Landing/Partners/ContactForm"

export function Contact() {
  return (
    <section className={styles.SectionWrapper}>
      <ContactForm />
    </section>
  )
}
